html,
body {
  height: 100%;
  overflow-x: hidden;
  margin: 0;
  font-family: var(--font-family);
  font-size: var(--font-size);
  line-height: 1.5;
  letter-spacing: normal;

  * {
    outline: none !important;
  }
}

.selected {
  background-color: rgba(var(--accent-color-rgb), 0.2);
}

.information {
  font-size: var(--font-size) - 1;
  margin-bottom: 10px;
}

.small-font {
  font-size: var(--font-size) - 2;
}

.text-warn {
  color: var(--warn-color);
}

.text-italic {
  font-style: italic;
}

.text-white {
  color: var(--white-color);
}

.text-info {
  color: var(--info-color);
}

.text-accent {
  color: var(--accent-color);
}

.text-primary {
  color: var(--primary-color);
}

.text-alert {
  color: var(--alert-color);
}

.bg-warn {
  background-color: var(--warn-color);
}

.bg-white {
  background-color: var(--white-color);
}

.bg-accent {
  background-color: var(--accent-color);
}

.bg-primary {
  background-color: var(--primary-color);
}

.bg-alert {
  background-color: var(--alert-color);
}

.bg-info {
  background-color: var(--info-color);
}

.app-spacer {
  flex: 1 1 auto;
}

.san-divider {
  border-top: 1px solid var(--accent-color);
  border-radius: 5px;
  margin: 10px 0px;
  width: 100%;
  display: inline-block;
}

.pointer {
  cursor: pointer;
}

.mouse-drag {
  cursor: move;
}

.full-height {
  height: 100%;
}

.full-width {
  width: 100%;
}

.center {
  text-align: center;
}

.disabled {
  pointer-events: none !important;
  cursor: default;
  opacity: 0.5;
}

.icon-state {
  color: var(--white-color);
  background-color: var(--primary-color);
}

.hidden {
  display: none;
  visibility: hidden;
}

.loading-text {
  top: 55% !important;
}

.relative {
  position: relative !important;
}

.small-icon {
  height: 12px !important;
  width: 12px !important;
  font-size: 12px !important;
}

.placeholder {
  opacity: 0.7 !important;
}

.dialog-content,
.small-mg {
  margin: 5px;
}

.small-pd {
  padding: 5px;
}

.san-dialog-container {
  margin-left: auto;
  margin-right: auto;
  padding: 5px;
  width: clamp(200px, 500px, 80vw);
  position: fixed !important;
  top: 120px;
}

.text-upper {
  text-transform: uppercase;
}

.form-errors {
  color: var(--warn-color);
  background: var(--white-color);
  padding: 2px;
}

.san-icon {
  margin: 0 5px;
}

.san-ml {
  margin-left: 20px;
}

.basic-overflow {
  overflow: visible;
  max-height: 250px;
  width: 100%;
}

.clear-icon {
  position: relative;
  right: 20px;
  margin-left: 0px;
  margin-right: -15px;
  z-index: 2;
  vertical-align: middle;
}

.san-spinner-loader {
  background-color: rgba(var(--accent-color-rgb), 0.1);
}

.exclude {
  opacity: 0.5;
  text-decoration-line: line-through;
  pointer-events: none !important;
  cursor: not-allowed !important;
}
