html * {
  scrollbar-width: thin;
  scrollbar-color: var(--accent-color) transparent;
}

/* Webkit */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/* Fond du scroll */
::-webkit-scrollbar-track {
  background: var(--accent-color);
  display: none
}

/* Barre du scroll */
::-webkit-scrollbar-thumb {
  background: var(--accent-color);

  &:hover {
    background-color: var(--primary-color);
  }
}