// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use 'sass:map';
@use '@angular/material' as mat;
// Plus imports for other components in your app.

@include mat.core();

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
//@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$santynelle-primary: mat.m2-define-palette(mat.$m2-teal-palette, 800);
$santynelle-accent: mat.m2-define-palette(mat.$m2-teal-palette, A700);

// The warn palette is optional (defaults to red).
$santynelle-warn: mat.m2-define-palette(mat.$m2-red-palette, 800);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$santynelle-theme: mat.m2-define-light-theme((color: (primary: $santynelle-primary,
        accent: $santynelle-accent,
        warn: $santynelle-warn,
      ), density:0));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($santynelle-theme);

mat-sidenav.mat-sidenav {
  min-width: 200px;
}

mat-sidenav.mat-sidenav,
mat-sidenav-content.mat-sidenav-content {
  top: 100px;
}


.mat-list-base .mat-list-item {
  font-size: var(--font-size);
}

mat-nav-list.mat-nav-list {
  .icon-unexpand {
    opacity: 0;
    visibility: hidden;
  }

  .mat-list-item:hover {
    background-color: transparent;
    color: var(--accent-color);
  }
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.6);
}