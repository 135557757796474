:root {
  --font-family: 'Roboto', 'Trebuchet MS';
  --font-size: 13.5px;
  --primary-color-rgb: 4, 141, 134;
  --accent-color-rgb: 0, 191, 165;
  --warn-color-rgb: 198, 40, 40;
  --white-color-rgb: 255, 255, 255;
  --info-color-rgb: 25, 118, 210;
  --alert-color-rgb: 251, 140, 0;

  --primary-color: rgb(var(--primary-color-rgb));
  --accent-color: rgb(var(--accent-color-rgb));
  --warn-color: rgb(var(--warn-color-rgb));
  --white-color: rgb(var(--white-color-rgb));
  --info-color: rgb(var(--info-color-rgb));
  --alert-color: rgb(var(--alert-color-rgb));

  --header-height: 60px;
  --footer-height: 40px;
  --content-height: calc(100vh - var(--header-height));
}