.fullscreen-icon {
	background-image: url('icon-fullscreen.svg');
	background-size: 26px 52px;
}

.fullscreen-icon.leaflet-fullscreen-on {
	background-position: 0 -26px;
}

.leaflet-touch .fullscreen-icon {
	background-position: 2px 2px;
}

.leaflet-touch .fullscreen-icon.leaflet-fullscreen-on {
	background-position: 2px -24px;
}

/* Safari still needs this vendor-prefix: https://caniuse.com/mdn-css_selectors_fullscreen */
/* stylelint-disable-next-line selector-no-vendor-prefix */
.leaflet-container:-webkit-full-screen {
	width: 100% !important;
	height: 100% !important;
	z-index: 99999;
}

.leaflet-container:fullscreen {
	width: 100% !important;
	height: 100% !important;
	z-index: 99999;
}

.leaflet-pseudo-fullscreen {
	position: fixed !important;
	width: 100% !important;
	height: 100% !important;
	top: 0 !important;
	left: 0 !important;
	z-index: 99999;
}
