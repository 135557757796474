html,
body {
  height: 100%;
  overflow-x: hidden;
  margin: 0;
  font-family: var(--font-family);
  font-size: var(--font-size);
  line-height: 1.5;
  letter-spacing: normal;

  * {
    outline: none !important;
  }
}

.page-background {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top left;
  z-index: -10000;
}

.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.footer {
  background-color: var(--white-color);
  color: var(--primary-color);
  border-top: 1px solid rgba(var(--accent-color-rgb), 0.3);
  padding: 5px 3px 5px 3px;
  white-space: nowrap;
  overflow: hidden;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100vw;
}

.font-bold {
  font-weight: bold;
}

.font-italic {
  font-style: italic;
}

.content {
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  background: transparent;
  margin-top: var(--header-height);
}

.single-content {
  min-height: var(--content-height);
  overflow: hidden;
}

.single-content-left {
  background-color: var(--white-color);
  border-right: 1px solid rgba(var(--accent-color-rgb), 0.3);
}

.single-content-right {
  padding: 10px;
}

.empty-page {
  height: 100vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  color: var(--primary-color);
  align-items: center;
  justify-content: center;
  margin-top: -100px;

  .native-loader {
    border: 4px solid rgba(var(--accent-color-rgb), 0.3);
    border-radius: 50%;
    border-top: 4px solid var(--accent-color);
    width: 30px;
    height: 30px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
  }

  .retry-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    margin: 5px;
    padding: 10px;
    border-radius: 4px;
    font-weight: 600;
    border: 1px solid var(--primary-color);
    cursor: pointer;
  }

  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}

.boldMenu {
  font-size: 17px;
  color: var(--primary-color);
}

.success-snack {
  background-color: var(--accent-color);
  color: var(--white-color);
}

.info-snack {
  background-color: var(--info-color);
  color: var(--white-color);
}

.warn-snack {
  background-color: var(--alert-color);
  color: var(--white-color);
}

.error-snack {
  background-color: var(--warn-color);
  color: var(--white-color);
}

.picture {
  display: flex;
  border: 1px solid rgba($color: var(--warn-color-rgb), $alpha: 0.2);
  border-radius: 10px;
}

.small-padding {
  padding: 3px;
}

.small-margin {
  margin: 3px;
}

.upper {
  text-transform: uppercase;
}

.round-content {
  background-color: var(--white-color);
  border-radius: 10px;
}

.link {
  color: var(--info-color);
  text-decoration: underline;
  cursor: pointer;
}

.full-heigth {
  height: 100%;
}

.fullVh {
  height: 100vh;
}

.user-photo {
  width: clamp(70px, 100px, 200px);
  height: clamp(70px, 100px, 200px);
}

.user-photo-small {
  width: 50px;
  height: auto;
  border-radius: 50px;
}

.btnFlag {
  width: 24px;
  margin-right: 10px;
  cursor: pointer;
}

.modal-body>div {
  margin-bottom: 10px;
}

.info {
  >div label {
    font-weight: bold;
    color: var(--accent-color);
  }
}

.badge {
  border: 1px solid var(--accent-color);
  color: var(--primary-color);
  border-radius: 4px;
  margin-bottom: 10px;
  padding: 5px;
  margin: 5px;
  outline: none;
  user-select: none;
}

.splash-image {
  width: clamp(200px, 300px, 400px);
}

.middle {
  margin-left: auto !important;
  margin-right: auto !important;
}

.san-spinner-loader {
  background-color: transparent;
}

san-bloc {
  width: 100%;
}

.big-font {
  font-size: 32px;
}

@media (max-width: 959px) {
  .big-font {
    font-size: 24px;
  }
}

@media (max-width: 500px) {
  .big-font {
    font-size: 18px;
  }
}

.empty {
  height: 100px;
}

.titre {
  color: var(--primary-color);
  font-size: 15px;
  font-weight: 700;
  padding-top: 5px;
  padding-bottom: 5px;
}

.mk-tooltip-warn {
  border: 1px solid rgba(var(--warn-color-rgb), .7);
}

.mk-tooltip-accent {
  border: 1px solid rgba(var(--accent-color-rgb), .7);
}

.mk-tooltip-primary {
  border: 1px solid rgba(var(--primary-color-rgb), .7);
}

.mk-tooltip-info {
  border: 1px solid rgba(var(--info-color-rgb), .7);
}

.mk-popup {
  .leaflet-popup-content-wrapper {
    border-radius: 0px;
  }
}