.form-label {
  color: var(--accent-color);
  margin-top: 10px;
}

.san-form-control-input {
  vertical-align: middle;
  resize: none;
  background-color: var(--white-color);
  background-repeat: no-repeat;
  background-position: right 8px center;
  border: 1px solid rgba(0, 0, 0, 0.2);
  outline: none;
  width: 100%;
  height: var(--input-height);

  &:focus {
    outline: none;
    border-color: var(--accent-color);
  }
}

.label-required::after {
  content: ' *';
}

.has-errors {
  border-color: var(--warn-color);
}

.input-suffix-btn {
  background-color: var(--primary-color);
  box-shadow: rgba(var(--primary-color-rgb), 0.1) 0 2px 4px;
  color: var(--white-color);
  display: inline-block;
  height: var(--btn-height);
  outline: none;
  overflow: hidden;
  position: relative;
  text-align: center;
  touch-action: manipulation;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  border: 0;
  min-width: 50px;

  .suffix-icon {
    margin: 0px;
  }
}

.info-text {
  text-overflow: ellipsis;
}

.control-margin {
  margin-top: 2.5px;
  margin-bottom: 2.5px;
}

.form-margin {
  margin: 5px 5px 5px 0px;
}